import { AppUser, IAppUser } from "./app-user.model";
import { FileApp, IFileApp } from "./file-app.model";

export interface IMachineType {
  idMachineType: string;
  idCompany: string;
  machineType: string;
  idFile: string;
  status: boolean;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  fileBase64?: string;
  createdAt: string;
  updatedAt: string;
  createdBy?: IAppUser;
  updatedBy?: IAppUser;
  file?: IFileApp;
}

export class MachineType {
  idMachineType: string;
  idCompany: string;
  machineType: string;
  idFile: string;
  status: boolean;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  fileBase64: string | null;
  createdAt: string;
  updatedAt: string;
  createdBy?: AppUser;
  updatedBy?: AppUser;
  file?: FileApp;

  constructor(data: IMachineType) {
    this.idMachineType = data.idMachineType;
    this.idCompany = data.idCompany;
    this.machineType = data.machineType;
    this.idFile = data.idFile;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.createdBy = data.createdBy
      ? new AppUser(data.createdBy)
      : undefined;
    this.updatedBy = data.updatedBy
      ? new AppUser(data.updatedBy)
      : undefined;
    this.fileBase64 = data.fileBase64 ?? null;
    this.file = data.file
      ? new FileApp(data.file)
      : undefined;
  }

  // Aquí puedes agregar otros métodos útiles relacionados con la clase MachineType
}
