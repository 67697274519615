import { Injectable } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';
import { IMachineType, MachineType } from '../models/machine-type.model';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class MachineTypeService {

  public machineTypes: MachineType[] = [];
  public loading: boolean = false;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
  ) { }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION create
   * 
   * @param idCompany 
   * @param machineType 
   * @returns 
   */
  async create(
    idCompany: string,
    machineType: IMachineType
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/companies/${idCompany}/machine_types/`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando tipo de maquinaria';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(machineType),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 201) {
        flag = true;
        this.getAll(
          idCompany
        );
      }
      

    } catch(error) {
      console.log(error);
      
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION delete
   * 
   * @param machineType 
   * @returns 
   */
  async delete(
    machineType: MachineType
  ): Promise<boolean> {
    let flag = false;

    const urlApi = `${environment.urlApi}/companies/${machineType.idCompany}/machine_types/${machineType.idMachineType}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Borrando tipo de maquinaria';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;
        this.getAll(
          machineType.idCompany
        );
      }
      

    } catch(error) {
      console.log(error);
      
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  /**
   * FUNCTION getAll
   * 
   * @param idCompany 
   */
  async getAll(
    idCompany: string
  ) {

    const urlApi = `${environment.urlApi}/companies/${idCompany}/machine_types/`;

    this.loading = true;

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ machineTypes: IMachineType[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ machineTypes: IMachineType[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ machineTypes: IMachineType[] }>
              ) => resolve(
                new ServerResponse<{ machineTypes: IMachineType[] }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        this.machineTypes = response.data!.machineTypes.map(
          machineType => new MachineType(machineType)
        );

        console.log(this.machineTypes);
        
      }

    } catch(error) {
      console.log(error);
      
    }

    this.loading = false;

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION update
   * 
   * @param machineType 
   * @returns
   */
  async update(
    machineType: MachineType,
  ): Promise<boolean> {
    let flag = false;

    const urlApi = `${environment.urlApi}/companies/${machineType.idCompany}/machine_types/${machineType.idMachineType}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando tipo de maquinaria';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(machineType),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;
        this.getAll(
          machineType.idCompany
        );
      }
      

    } catch(error) {
      console.log(error);
      
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }
}
